import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  Image,
  Heading,
  Stack,
} from "@chakra-ui/react"
import Header from "./Header"
import EmailSignup from "../EmailSignup"

export default function Hero({
  title,
  subtitle,
  image,
  ctaLink,
  ctaText,
  ...rest
}) {
  return (
    <Box
      position="relative"
      w="full"
      h={{
        base: "full",
        md: "calc((50vw * .65 * 1.1) + 64px)" ,
        xl: "calc((50vw * .65 * 1.1) + 64px)",
        '2xl': "calc((1536px * .35) + 64px)"
      }}
      mb={{ base: 32, md: 8, xl: 16 }}
      {...rest}
    >
      {/* Header */}
      <Header />

      <Box
        position="inline"
        w="full"
        h={{ base: "calc((55vw * .65) + 200px + 64px)", md: "90%" }}
        pt="64px"
        px={{ base: 8, md: 16}}
        bgGradient="linear(140deg, #0694C6 -10%, #9E1F63 30%, #9E1F63 40%, #0694C6 100%)"
      >
          {/* Text and sign up field */}
          <Stack
            mt="28px"
            direction="column"
            spacing={{ base: 4, md: 4, lg: 8}}
            w={{ base: "100%", md: "40%", xl: "1280px * .4"}}
            pl={{ xl: "calc((100vw - 1280px) / 2 - 32px)" }}
            alignItems={{ base: "center", md: "left" }}
          >
            <Heading
              as="h1"
              size={{ base: "lg", lg: "xl" }}
              fontWeight="normal"
              color="white"
              textAlign={{ base: "center", md: "left"}}
            >
              Revolutionize your practice management
            </Heading>
            <Heading
              as="h2"
              size={{ base: "sm", lg: "md" }}
              w="full"
              color="white"
              opacity="0.8"
              fontWeight="normal"
              lineHeight={1.5}
              textAlign={{ base: "center", md: "left"}}
            >
              {subtitle}
            </Heading>
            <EmailSignup
              w="100%"
              justifyContent={{ base: "center", md: "left"}}
              placeholder={ctaText}
            />
          </Stack>
          {/* Image */}
          <Box
            position={{base: "absolute", md: "absolute" }}
            top={{base: "", md: "96px"}}
            mt={{base: "32px", md: "0"}}
            marginBottom={{ base: "-5%", md: "0" }}
            marginLeft={{
              base: "0",
              md: "calc(100vw * .4 - 16px)",
              xl: "calc((1280px * .4 + (100vw - 1280px) / 2)) - 8px)",
              "2xl": "calc((((100vw - 1280px) / 2) + (1280px * .4) - 32px",
            }}
            w={{ base: "200px", md: "50vw", "2xl": "50vw"}}
            maxW={{ base: "380px", xl: "720px", "2xl":"768px"}}
          >
            <Image w={{ base: "90%", md: "100%"}} maxW="inherit" src={image} rounded="1rem" boxShadow="2xl" />
          </Box>
      </Box>
    </Box>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
}

Hero.defaultProps = {
  title: "Revolutionize your practice management",
  subtitle:
    "Everything you need to run your practice in one place",
  image: "/images/calendar_collapsed__2_.png",
  //image: "/images/calendar_headercolor.png",
  ctaText: "Sign up to be notified when we launch",
}
