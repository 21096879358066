import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
} from '@chakra-ui/react';
import theme from './theme/theme';
import LandingLayout from './components/layouts/LandingLayout';
import ContentLayout from './components/layouts/ContentLayout';
import Hero from './components/sections/Hero';
import Card from './components/sections/Card';

import { lorem } from './lorem';
import { cardData } from './CardData';

import './theme/styles.css';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box w="100%" maxW="100vw" h="full" p={0} m={0} overflow="hidden" >
        <LandingLayout
          w="inherit"
          maxW="inherit"
          h="full"
          overflowX="hidden"
          overflowY="auto"
        >
          <Hero />
          <ContentLayout py={8} >
            {
              cardData.map((card, i) => {
                return(
                  <Card
                    key={i}
                    direction={i % 2 === 0 ? "reverse" : ""}
                    title={card.title}
                    content={card.bullets}
                    image={card.image}
                    mb={20}
                  />
                )
              })
            }
          </ContentLayout> 
        </LandingLayout>
      </Box>
    </ChakraProvider>
  );
}

export default App;
