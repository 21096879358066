import React from 'react';
import axios from 'axios';
import {
  HStack,
  Input,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const EmailSignup = ({ placeholder, ...props }) => {
  const[email, setEmail] = React.useState("");
  const[loading, setLoading] = React.useState(false);
  const toast = useToast();
  const successToast = {
    title: 'Success',
    description: "You've been added to our list!",
    status: 'success',
    duration: 9000,
    isClosable: true,
  };
  const dupToast = {
    title: 'Success',
    description: "You're already on our list!",
    status: 'success',
    duration: 9000,
    isClosable: true,
  };
  const failToast = {
    title: 'Error',
    description: "Something went wrong!",
    status: 'error',
    duration: 9000,
    isClosable: true,
  };
  const emailErrorToast = {
    title: 'Error',
    description: "Please enter a valid email",
    status: 'error',
    duration: 9000,
    isClosable: true,
  }

  const validate = () => {
    let emailError = false;
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!regexEmail.test(email) || !email) {
      //errors.email = "Please enter a valid email";
      emailError = true;
    }
    //console.log(emailError)
    return emailError;
  }

  const handleSubmit = () => {
    //console.log("clicked " + email);
    //console.log(validate());
    if (validate() === true){
      toast(emailErrorToast);
    } else {
      setLoading(true);
      axios.post("https://kaizen-email-list.fly.dev/add-email",
        {"email": email},
        {
          timeout: 4000,
          headers: {'Access-Control-Allow-Origin': '*'}
        })
        .then(result => {
          // console.log(result)
          // console.log("posted")
          if(result.data.success) {
            if(result.data.status === 0) toast(successToast);
            else if(result.data.status === 2) toast(dupToast);
          } else {
            toast(failToast);
          } 
          setLoading(false);
        })
        .catch(e => {
          setLoading(false);
          //console.log("error")
          toast(failToast);
        });
    }
  }

  const handleChange = e => {
    setEmail(e.target.value);
    //console.log(email);
  }

  return (
    <HStack {...props}>
      <Input
        color="white"
        _placeholder={{ opacity: 0.6, color: 'white' }}
        variant="flushed"
        placeholder={placeholder ? placeholder : "Email"}
        value={email}
        onChange={handleChange}
        onKeyPress={e=> {
          if (e.key === 'Enter') {
            handleSubmit();
          }
       }}
      />
      <IconButton 
        borderRadius="full"
        icon={<ArrowForwardIcon />}
        onClick={handleSubmit}
        isLoading={loading}
      />
    </HStack>
  );
}

export default EmailSignup;