import React from "react"
import { Button, Flex, Heading} from "@chakra-ui/react"
import Header from "../sections/Header"
import Footer from "../sections/Footer"

export default function LandingLayout(props) {
  return (
    <Flex
      direction="column"
      align="center"
      {...props}
    >
      {/* <Button onClick={() => scrollToBottom()}>test</Button> */}
      {/* <Header /> */}
      {props.children}
      <Footer />
    </Flex>
  )
}