import {
  Box,
  Flex,
  Heading,
  Image,
  UnorderedList,
  ListItem,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function Card({title, content, image, direction, ...rest}) {
  const rowDir = direction === "reverse" ? "row-reverse" : "row";
  const layoutDir = useBreakpointValue({base: "column-reverse", md: rowDir});

  return(
    <Flex
      w="100%"
      h="100%"
      justifyContent="space-between"
      flexDir={layoutDir}
      spacing=""
      alignItems="center"
      {...rest}
    >
      <VStack
        w={{ base: "80%", md: "60%" }}
        h="100%"
        alignItems="start"
        justifyContent="start"
      >
        <Heading
          w="full"
          fontWeight="normal"
          color="kzmaroon"
          textAlign={{ base: "center", md: "left" }}
        >
          {title}
        </Heading>
        <UnorderedList pl={{ base: 0, md: 10 }}>
          {content.map((point, i) => (<ListItem key={title + i}>{point}</ListItem>))}
        </UnorderedList>
      </VStack>
      <Box w={{base: "50%", md: "30%"}} bgGradient="linear(to-br, #0694C6, #9E1F63)">
        <Image src={image} alt={title} />
      </Box>
    </Flex>
  )
}