export const cardData = [
  {
    title: "Seamless scheduling",
    bullets: [
      "Define your operating hours and available appointment slots for clients to choose, leaving you with less time organizing your calendar and more time with your clients.", 
      "Easily reschedule, cancel, and even add follow-up notes all in one place."
    ],
    image: "/images/cuatecalendar1.png"
  },
  {
    title: "In-platform messaging",
    bullets: [
      "Build stronger relationships with your clients outside the appointment window using Kaizen's in-platform messaging interface.",
      "Send documents, share updates, and inquire about progress in a secure, encrypted, environment.",
    ],
    image: "/images/cuate 2.png",
  },
  {
    title: "Frictionless billing",
    bullets: [
      "Manage transactions, claims, statements, and superbills in one secure centralized hub.",
      "Kaizen automatically captures important client and appointment information for insurance forms and statements, leaving you with a stress-free billing experience.",
      "Easily keep track of recent transactions and insurance payers with the support of automated alerts so you can stay on top of upcoming deadlines.",
    ],
    image: "/images/pana 1.png",
  },
  {
    title: "Powerful reporting dashboard",
    bullets: [
      "Display valuable information from your billing income to CPT code usage so that you can stay up to date with the financial and operational health of your practice.",
      "Leverage a live platform dashboard or generate in-depth reports with only the information that's most important to you with a single click.",
    ],
    image: "/images/cuate 3.png",
  },
  {
    title: "Telehealth powered by Zoom",
    bullets: [
      "Easily add Zoom functionality to virtual appointments to expand your practice flexibility and cater to the comfort of your clients.",
      "Engage with built-in tools such as virtual whiteboards to provide support from anywhere in the world.",
    ],
    image: "/images/rafiki 1.png",
  },
  {
    title: "Customizable client profiles",
    bullets: [
      "Each client is unique, that's why Kaizen allows you to customize your client profile layouts to only have the most appropriate information.",
      "Add or remove widgets for current medications, documents, emergency contacts, and more to create the perfect profile for each of your clients.",
      "Save any particular layout as a template to reuse when onboarding new clients.",
    ],
    image: "/images/cuate 1.png",
  },
  {
    title: "Securely shared documents",
    bullets: [
      "Each client is unique, that's why Kaizen allows you to customize your client profile layouts to only have the most appropriate information.",
      "Add or remove widgets for current medications, documents, emergency contacts, and more to create the perfect profile for each of your clients.",
      "Save any particular layout as a template to reuse when onboarding new clients.",
    ],
    image: "/images/cuate 4.png",
  },
];
