import React, { useEffect } from "react"
//import { Link as ReactLink } from "react-router-dom"
import {
  Circle,
  Flex,
  Hide,
  Image,
  Link,
  Text,
  HStack,
} from "@chakra-ui/react"

const Header = (props) => {
  const [scrollPos, setScrollPos] = React.useState(0);
  
  const handleScroll = () => {
    setScrollPos(window.scrollY);
  }

  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
  
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth"
    });
  };

  const scrolled = scrollPos > 12;
  const transition = {
    transitionTimingFunction:"ease-in",
    transitionDelay:"0.05s",
    transitionDuration:"0.2s"
  }

  return (
    <Flex
      position={"fixed"}
      top="0%"
      h="64px"
      w="full"
      justifyContent="center"
      px={{ base: 0, md: 8}}
      bg="none"
      mt={scrolled ? 0 : 4}
      zIndex="banner"
      {...transition}
    >
      <HStack
        justifyContent="space-between"
        h="90%"
        w="full"
        maxW="container.xl"
        bg={scrolled ? "#F4F6FA" : "none"}
        borderRadius="full"
        px={{base: 8, md: 8}}
        boxShadow={scrolled ? "xl" : ""}
        {...transition}
      >
        <HStack spacing={2} onClick={() => scrollToTop()} cursor="pointer">
          <Circle size='36px' bg='white' 
              boxShadow="lg">
            <Image
              boxSize='24px'
              src='/kaizen-logo.png'
              alt='Kaizen logo'
            />
          </Circle>
          <Text
            fontSize={{base: "xl", md: "30px"}}
            color={scrolled ? "default" : "white"}
            _hover={{textDecoration:"underline"}}
            {...transition}
          >
            Kaizen
          </Text>
        </HStack>
        <HStack>
          {/* <Text 
            color={scrolled ? "default" : "white"} 
            {...transition}
          >
            About <Hide breakpoint="(max-width: 400px)">Us</Hide>
          </Text> */}
          <Link
            color={scrolled ? "default" : "white"}
            onClick={() => scrollToBottom()}
            {...transition}
          >
            Contact <Hide breakpoint="(max-width: 400px)">Us</Hide>
          </Link>
        </HStack>
      </HStack>
    </Flex>
  )
}

export default Header