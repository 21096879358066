export const FaceBookIconSVG = () => {
  return (
    <svg width="14" height="26" viewBox="0 0 14 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9062 9.25008H9.5V6.75008C9.5 5.46008 9.605 4.64758 11.4537 4.64758H13.7887V0.672578C12.6525 0.555078 11.51 0.497578 10.3662 0.500078C6.975 0.500078 4.5 2.57133 4.5 6.37383V9.25008H0.75V14.2501L4.5 14.2488V25.5001H9.5V14.2463L13.3325 14.2451L13.9062 9.25008Z" fill="#3B5998"/>
    </svg>
  );
}

export const InstaIconSVG = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0.75C3.54875 0.75 0.75 3.54875 0.75 7V17C0.75 20.4512 3.54875 23.25 7 23.25H17C20.4512 23.25 23.25 20.4512 23.25 17V7C23.25 3.54875 20.4512 0.75 17 0.75H7ZM19.5 3.25C20.19 3.25 20.75 3.81 20.75 4.5C20.75 5.19 20.19 5.75 19.5 5.75C18.81 5.75 18.25 5.19 18.25 4.5C18.25 3.81 18.81 3.25 19.5 3.25ZM12 5.75C15.4512 5.75 18.25 8.54875 18.25 12C18.25 15.4512 15.4512 18.25 12 18.25C8.54875 18.25 5.75 15.4512 5.75 12C5.75 8.54875 8.54875 5.75 12 5.75ZM12 8.25C11.0054 8.25 10.0516 8.64509 9.34835 9.34835C8.64509 10.0516 8.25 11.0054 8.25 12C8.25 12.9946 8.64509 13.9484 9.34835 14.6517C10.0516 15.3549 11.0054 15.75 12 15.75C12.9946 15.75 13.9484 15.3549 14.6517 14.6517C15.3549 13.9484 15.75 12.9946 15.75 12C15.75 11.0054 15.3549 10.0516 14.6517 9.34835C13.9484 8.64509 12.9946 8.25 12 8.25Z" fill="url(#paint0_radial_3700_12530)"/>
    <defs>
      <radialGradient id="paint0_radial_3700_12530" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.375 1.375) rotate(133.091) scale(26.5312 30.5699)">
        <stop stopColor="#C13584"/>
        <stop offset="0.261639" stopColor="#9144A8" stopOpacity="0.760541"/>
        <stop offset="0.715709" stopColor="#405DE6" stopOpacity="0.354167"/>
        <stop offset="1" stopColor="#F77737"/>
      </radialGradient>
    </defs>
    </svg>
  );
}



export const LinkedInIconSVG = () => {
  return (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.75 0.75H3.25C1.86875 0.75 0.75 1.86875 0.75 3.25V20.75C0.75 22.1313 1.86875 23.25 3.25 23.25H20.75C22.1313 23.25 23.25 22.1313 23.25 20.75V3.25C23.25 1.86875 22.1313 0.75 20.75 0.75ZM8.25 18.25H5.09625V9.5H8.25V18.25ZM6.6175 7.89625C5.65375 7.89625 5.01 7.25375 5.01 6.39625C5.01 5.53875 5.6525 4.89625 6.72375 4.89625C7.6875 4.89625 8.33125 5.53875 8.33125 6.39625C8.33125 7.25375 7.68875 7.89625 6.6175 7.89625ZM19.5 18.25H16.4475V13.4675C16.4475 12.145 15.6338 11.84 15.3288 11.84C15.0238 11.84 14.0062 12.0437 14.0062 13.4675C14.0062 13.6712 14.0062 18.25 14.0062 18.25H10.8525V9.5H14.0062V10.7213C14.4125 10.0088 15.2263 9.5 16.7525 9.5C18.2788 9.5 19.5 10.7212 19.5 13.4675V18.25Z" fill="#0072B1"/>
  </svg>

  );
}
