import React from "react";
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Link,
  VStack,
 } from "@chakra-ui/react"
 import { EmailIcon } from "@chakra-ui/icons";
 import { FaceBookIconSVG, InstaIconSVG, LinkedInIconSVG } from "../Icons";
 import EmailSignup from "../EmailSignup";

export default function Footer() {

  return(
    <Box w="full" h="fit-content">
      <VStack bgGradient="linear(to-r, #9E1F63 -10%, #0694C6)" p={10} spacing={4}>
        <Heading color="white" fontWeight="normal">Sign up to be notified when we launch</Heading>
        <EmailSignup w={{ base: "80%", md: "40%" }} />
      </VStack>
      <HStack w="full" justifyContent="center" p={8} spacing={8}>
        <IconButton as={Link}
          icon={<FaceBookIconSVG />} borderRadius="full" bg="none" boxShadow="lg" 
          href="https://www.facebook.com/Kaizen.EHR"
          rel="noopener noreferrer nofollow"
          target="_blank"
        />
        <IconButton as={Link}
          icon={<InstaIconSVG />} borderRadius="full" bg="none" boxShadow="lg" 
          href="https://www.instagram.com/kaizen.ehr"
          rel="noopener noreferrer nofollow"
          target="_blank"
        />
        <IconButton as={Link}
          icon={<LinkedInIconSVG />} borderRadius="full" bg="none" boxShadow="lg"
          href="https://www.linkedin.com/company/kaizen-ehr"
          rel="noopener noreferrer nofollow"
          target="_blank"
        />
        <IconButton as={Link}
          icon={<EmailIcon boxSize="18px" color="purple.700"/>} 
          borderRadius="full" 
          boxShadow="lg"
          bg="none"
          //bgGradient="linear-gradient(to-tr, #0694C6 -20%, #9E1F63 50%, #0694C6)"
          href="mailto:info@project-kaizen.io"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
        </IconButton>
      </HStack>
    </Box>
  );
}

