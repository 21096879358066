import React from "react"
import { Flex, Heading } from "@chakra-ui/react"

export default function ContentLayout(props) {
  return (
    <Flex
      direction="column"
      align="center"
      maxW="container.lg"
      m="0 auto"
      px={{ base: 8, md: 16}}
      {...props}
    >
      <Heading textAlign="center" fontWeight="normal" mb={8}>
        Spend less time on administrative tasks and more time with your clients
      </Heading>
      <Heading textAlign="center" fontSize="md" fontWeight="normal" mb={16}>
        Kaizen brings all of the systems and tools you use on a daily basis into one convenient and user-friendly platform.
      </Heading>
      {props.children}
    </Flex>
  )
}
