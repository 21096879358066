import {
  extendTheme,
  theme as base,
} from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    kzmaroon: '#9A6B76',
    kzdarkgrey: '#666666',
  },
  fonts: {
    heading: `Poppins, ${base.fonts?.heading}`,
    body: `Poppins, ${base.fonts?.body}`,
  },
});

export default theme;